import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import api from "../API/FetchApi";
import api from "../API/apiCollection";
import axios from 'axios';
import { resetState } from "../redux/cart";
import { orderCartDetailsReset } from "../redux/orderCartDetails";
import { deliveryAddressReset } from "../redux/DeliveryAddress";
import { useNavigate } from "react-router";

const Chapa = ({ amount, promoCode = "" }) => {
  const settings = useSelector((state) => state?.Settings)?.settings
    ?.payment_gateways_settings;
  const orderDetails = useSelector(
    (state) => state.OrderCartDetails
  )?.orderDetails;
  const delivery_type = useSelector(
    (state) => state.DeliveryAddress
  )?.deliveryType;
  const date = orderDetails && orderDetails.date;
  const time = orderDetails && orderDetails.slot;

  const [order_id, setOrderID] = useState(0);
  const [open, setOpen] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = async () => {
    setOpen(false);
    await api.add_transactions({ orderID: order_id, status: "cancelled" }).then((result) => {
      navigate("/profile/booking/services/" + order_id);
    });
  };

  const handlePayout = async () => {
    try {
      const result = await api.placeOrder({
        method: "chapa",
        date: date,
        time: time,
        addressId: delivery_type === "Home" ? orderDetails?.selectedAddress?.id : "",
        order_note: orderDetails.orderNote,
        promoCode: promoCode?.length > 0 ? promoCode[0]?.promo_code : "",
      });

      if (result.error === false || result.error === "false") {
        setOrderID(result.data.order_id);
        initializeChapaPayment(result.data.order_id, amount);
      } else {
        if (typeof result.message === "object") {
          Object.values(result.message).forEach((e) => {
            toast.error(e);
          });
        } else {
          toast.error(result.message);
        }
      }
    } catch (error) {
      console.error("Error placing order:", error);
      toast.error("Failed to place order. Please try again later.");
    }
  };


  const initializeChapaPayment = async (orderId, orderAmount) => {
    try {
      const data = await api.initializeChapaPayment(orderId, orderAmount, settings.chapa_secret_key);
      
      if (data.status === "success") {
        setPaymentUrl(data.data.checkout_url);
        setOpen(true);
      } else {
        toast.error("Failed to initialize payment");
      }
    } catch (error) {
      console.error("Error in handleChapaPayment:", error);
      toast.error("Failed to initialize payment");
    }
  };

  // const initializeChapaPayment = async (orderId, orderAmount) => {
  //   try {
  //     const response = await axios.post(
  //       "https://api.chapa.co/v1/transaction/initialize",
  //       {
  //         amount: orderAmount,
  //         currency: "ETB",
  //         tx_ref: orderId,
  //         callback_url: 'https://tenalink.et',
  //         meta: {
  //           is_subscription: "no",
  //           transaction_id: orderId,
  //         },
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${settings.chapa_secret_key}`,
  //         },
  //       }
  //     );

  //     const data = response.data;
  //     if (data.status === "success") {
  //       setPaymentUrl(data.data.checkout_url);
  //       setOpen(true);
  //     } else {
  //       toast.error("Failed to initialize payment");
  //     }
  //   } catch (error) {
  //     console.error("Error initializing payment:", error);
  //     toast.error("Failed to initialize payment");
  //   }
  // };

  return (
    <Box>
      <Button
        fullWidth
        sx={{ my: 3 }}
        variant="outlined"
        onClick={handlePayout}
      >
        {t("make_a_payment")}
      </Button>

      <Dialog open={open} maxWidth={"lg"}>
        <Box width={{ xs: "100%", md: 500 }} height={400}>
          <DialogTitle
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h6">Pay with Chapa</Typography>
            <IconButton
              aria-label="handle-close"
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <iframe
              src={paymentUrl}
              width="100%"
              height="100%"
              frameBorder="0"
              onLoad={() => {
                window.addEventListener("message", (event) => {
                  if (event.origin === "https://api.chapa.co" && event.data.status) {
                    if (event.data.status === "success") {
                      api.add_transactions({ orderID: order_id, status: "success" }).then(() => {
                        dispatch(resetState());
                        dispatch(orderCartDetailsReset());
                        dispatch(deliveryAddressReset());
                        navigate("/profile/booking/services/" + order_id);
                      });
                    } else {
                      toast.error("Payment failed");
                      api.add_transactions({ orderID: order_id, status: "failed" }).then(() => {
                        navigate("/profile/booking/services/" + order_id);
                      });
                    }
                  }
                });
              }}
            ></iframe>
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
};

export default Chapa;
